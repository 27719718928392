<script setup lang="ts">
const props = withDefaults(
	defineProps<{
		color?: string;
		duration?: number;
		width?: number;
		timingFunction?: "ease-in" | "ease-out" | "ease-in-out" | "linear";
		active?: boolean;
	}>(),
	{ color: "var(--cali)", duration: 1.5, width: 60, timingFunction: "ease-in", active: false }
);

const colorVar = computed(() => `0 0 0 0 ${props.color}`);
const durationVar = computed(() => `${props.duration}s`);
const timingFunctionVar = computed(() => `${props.timingFunction}`);
const widthVar = computed(() => `${props.width}px`);
</script>

<template>
	<div :class="{ pulse: active }">
		<slot />
	</div>
</template>

<style lang="scss">
:root {
	--a-animation-pulse-box-shadow-color: var(--camayenne);
}
.tao:root {
	--a-animation-pulse-box-shadow-color: var(--cheng);
}
.fortuneWheelz:root {
	--a-animation-pulse-box-shadow-color: var(--chaoyang);
}
@if mixin-exists(AAnimationPulse) {
	@include AAnimationPulse;
}
</style>
<style scoped lang="scss">
@keyframes pulse {
	0% {
		transform: scale(1);
	}

	70% {
		transform: scale(0.95);
		box-shadow: 0 0 0 v-bind(widthVar) var(--a-animation-pulse-box-shadow-color);
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 var(--a-animation-pulse-box-shadow-color);
	}
}

.pulse {
	& > :deep(*) {
		position: relative;
		overflow: visible;

		&:after {
			content: "";
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: block;
			position: absolute;
			border-radius: inherit;
			z-index: 1;
			background: transparent;
			animation-name: pulse;
			animation-duration: v-bind(durationVar);
			animation-timing-function: v-bind(timingFunctionVar);
			animation-iteration-count: infinite;
			box-shadow: v-bind(colorVar);
		}
		&:hover {
			&:after {
				animation-name: none;
			}
		}
	}
}
</style>
